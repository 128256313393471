.section-image {
	background-size: cover;
	min-height: 100vh;
	padding: 100px 0 120px;
	background-position: 50%;
}

.login-container {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.card-login {
	width: 70%;
	border-radius: 12px !important;
	padding-bottom: 0.7rem;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
	background-color: #fff;
	color: #252422;
	transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease !important;
}

.card-header-multimedia {
	padding: 15px 15px 0 !important;
	background: inherit;
	text-align: center;
}

.card-content {
	padding: 15px 15px 10px !important;
}

.card-action {
	padding: 0.75rem 1.25rem;
}

.input {
	width: 100%;
}

.button-login {
	background-color: #662D91 !important;
	color: #fff !important;
	border-radius: 30px !important;
	width: 100%;
	font-weight: 700 !important;
	margin-bottom: 20px !important;
}

.button-passwd {
	background-color: #69DB47 !important;
	color: #fff !important;
	border-radius: 30px !important;
	width: 100%;
	font-weight: 700 !important;
	margin-bottom: 20px !important;
}
